<template>
    <section class="container is-max-desktop">
        <b-breadcrumb
        size="is-small"
        align="is-left"
    >
        <b-breadcrumb-item tag="router-link" to="/">Home</b-breadcrumb-item>
        <b-breadcrumb-item
            tag="router-link"
            to="/portugal-architecture"
            active
        >
        Architecture
        </b-breadcrumb-item>
    </b-breadcrumb>
    
        <h1 class="has-text-weight-semibold mb-5 is-size-4">
            Architecture
          </h1>
    
        <div v-if="similarActivities.length" class="mb-3 mt-4">
            <TicketsAndActivitiesContainer
              is-full-width
              :is-experiences-ids="false"
              :Experiences="similarActivities"
              :Tickets="[]"
            />
            <RequestTour class="mt-3"/>
          </div>
          <p class="mb-3 is-size-5 has-text-weight-semibold mb-5 mt-6">Other Categories</p>
    <CategoriesList/>
        
    </section>
    </template>
    
    <script>
    import { mapActions, mapState } from "vuex";
    import {
        ActivitiesActions,
      ActivitiesStates,
    } from "../store/Storetypes";
    import TicketsAndActivitiesContainer from "../components/TicketsAndActivitiesContainer.vue";
    import RequestTour from "../components/elements/RequestTour.vue";
    import CategoriesList from "./CategoriesList.vue";
    export default{
        components: {
        TicketsAndActivitiesContainer,
        RequestTour,
        CategoriesList
      },
      metaInfo() {
        return {
          title: "Uncover Portugal's Architectural Heritage with Our Tours",
          titleTemplate: `Uncover Portugal's Architectural Heritage with Our Tours | Guiders `,
          keywords: "architecture tours in Portugal, best architectural tours Portugal, Portugal architecture sightseeing, top architectural landmarks Portugal, Portugal architectural heritage tours, explore Portugal's architecture, guided architecture tours Portugal",
          meta: [
            {
              vmid: "description",
              name: "description",
              content: "Explore Portugal's stunning architecture with our expert-led tours. Visit iconic buildings, historical landmarks, and modern masterpieces. Book your architectural tour of Portugal today!",
            },
            {
              vmid: "og:title",
              property: "og:title",
              content: `Uncover Portugal's Architectural Heritage with Our Tours | Guiders `,
            },
            {
              vmid: "og:description",
              property: "og:description",
              content: "Explore Portugal's stunning architecture with our expert-led tours. Visit iconic buildings, historical landmarks, and modern masterpieces. Book your architectural tour of Portugal today!",
            },
            {
              vmid: "og:url",
              property: "og:url",
              content: "https://guiders.pt/tours/portugal-architecture",
            },
          ],
          link: [{ rel: "canonical", href: "https://guiders.pt/tours/portugal-architecture" }],
        };
      },
        mounted() {
            window.scrollTo(0, 0);
            this.getActivities()
      },
      computed: {
        ...mapState({
          activities: (state) => state.activities[ActivitiesStates.ACTIVITIES] }),
          similarActivities() {
          const activityId = 3
          const otherActivities = this.activities.filter(
            (activity) => activity.categories.includes(activityId)
          );
          return otherActivities
        },
    },
      methods: {
        ...mapActions({
                getActivities: ActivitiesActions.GET_ACTIVITIES,
            }),
        
      }
    }
    </script>
    
    <style lang="scss" scoped>
    
    ::v-deep{
        .breadcrumb.is-small {
        font-size: 0.9rem;
    }
    
    .breadcrumb a {
        color: #167df0;
    }
    }
    
    </style>